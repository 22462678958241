import classNames from 'classnames'
import classes from './Options.module.scss'

const Options = ({ children, secondary, style }) => {
  return (
    <div className={classNames(classes['options'], style)}>
      <div
        className={classNames(
          classes['options__buttons'],
          secondary && classes['options__buttons--secondary'],
        )}
      >
        {children}
      </div>
    </div>
  )
}

Options.Item = ({ onChange, value, label, checked, name, style }) => {
  return (
    <div className={classNames(classes['btn'], style)} key={value}>
      <input
        key={value}
        type="radio"
        id={value}
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label className="h5" htmlFor={value}>
        {label}
      </label>
    </div>
  )
}

export default Options
