import { useState, useEffect } from 'react'
import Image from 'next/image'
import { PortableText } from '@portabletext/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import classes from './HarvestCard.module.scss'
import { useMarketingContext } from '@/context/MarketingContext'

const HarvestCard = ({
  fish,
  cardStyle,
  alternateTab,
  navEnabled = true,
  swapable = false,
  actionButtonDisabled,
  actionButtonIcon = <SwapHorizontalCircleIcon />,
  actionButtonText = 'Swap',
  onActionButton,
  onRemove,
  removeButtonText = 'Remove',
  removeInProgressText = 'Removing...',
  removeButtonIcon = <CancelIcon />,
  addon,
}) => {
  const [tabInfo, setTabInfo] = useState(
    !!alternateTab ? fish[alternateTab] : fish['species'],
  )
  const [removing, setRemoving] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  useEffect(() => {
    setMounted(true)
    setThumbsSwiper()
  }, [thumbsSwiper])

  const findTabInfo = (category) => {
    setTabInfo(fish[category])
  }

  const actionButtons = swapable && (
    <div className={classes['action-button-container']}>
      {removeButtonText && onRemove && (
        <button
          className={classes['action-button']}
          disabled={removing || actionButtonDisabled}
          onClick={async () => {
            setRemoving(true)
            try {
              await onRemove()
            } finally {
              setRemoving(false)
            }
          }}
        >
          {removeButtonIcon}{' '}
          {removing ? removeInProgressText : removeButtonText + ' x ' + addon?.qty}
        </button>
      )}
      {actionButtonText && onActionButton && (
        <button
          className={classes['action-button']}
          disabled={removing || actionButtonDisabled}
          onClick={() => onActionButton()}
        >
          {actionButtonIcon} {actionButtonText}
        </button>
      )}
    </div>
  )

  const getIcon = (category) => {
    switch (category) {
      case 'species':
        return '/species.png'
      case 'culinary':
        return '/culinary.png'
      case 'locations':
        return '/location.png'
      default:
        return '/sourcing.png'
    }
  }

  // TODO: clean up all these different instances of harvest images or add descriptions of why each one is needed and what it does -- ZJ

  return (
    <div
      className={`${cardStyle === 'projected-card' ? 'projected-slider' : ''} ${classes['harvest__card']} ${cardStyle === 'projected-card' ? classes['projected-card'] : ''}`}
    >
      {cardStyle === 'projected-card' &&
        tabInfo?.image?.asset?.url &&
        tabInfo[0]?._type !== 'fishermen' && (
          <ResponsiveComponent
            desktopBreakpoint={'xs'}
            className={`harvest__card-img ${classes['harvest__card-img']}`}
            mobile={
              <>
                {actionButtons}
                <div className={classes['image']}>
                  <Image
                    src={tabInfo.image.asset.url}
                    alt={tabInfo.title}
                    objectFit="cover"
                    fill
                  />
                </div>
              </>
            }
            desktop={
              <>
                {actionButtons}
                <div className={classes['image']}>
                  <Image
                    src={tabInfo.image.asset.url}
                    alt={tabInfo.title}
                    objectFit="cover"
                    fill
                  />
                </div>
              </>
            }
          />
        )}

      {cardStyle !== 'projected-card' &&
        tabInfo?.image?.asset?.url &&
        mounted &&
        tabInfo[0]?._type !== 'fishermen' && (
          <div className={`harvest__card-img ${classes['harvest__card-img']}`}>
            <Image
              src={tabInfo.image.asset.url}
              alt={tabInfo.title}
              fill
              objectFit="cover"
            />
          </div>
        )}

      {tabInfo[0]?._type === 'fishermen' && (
        <Swiper
          navigation={true}
          slidesPerView={1}
          onSwiper={setThumbsSwiper}
          modules={[Navigation, Thumbs]}
          thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
          threshold={15}
          className="fishermen-swiper"
        >
          {fish.fishermen.map((fishermen) => {
            return (
              <SwiperSlide key={`${fishermen.title}`}>
                <div
                  className={`harvest__card-img ${classes['harvest__card-img']}`}
                >
                  <Image
                    src={fishermen?.image?.asset.url}
                    alt={fishermen.title}
                    fill
                    objectFit="cover"
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}

      <div className={classes['harvest__card-inner']}>
        {navEnabled && (
          <div className={classes['harvest__card-tabs']}>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={16}
              threshold={15}
              breakpoints={{
                1024: {
                  spaceBetween: cardStyle === 'projected-card' ? 36 : 60,
                },
              }}
              className={classes['harvest__card-swiper']}
            >
              {Object.keys(fish)
                .filter(
                  (key) =>
                    key === 'species' ||
                    key === 'locations' ||
                    key === 'fishermen' ||
                    key === 'culinary',
                )
                .map((fishCategory) => {
                  return (
                    <SwiperSlide
                      key={`${fish.species.handle.current}-${fishCategory}`}
                      className={`${tabInfo._type === fishCategory || tabInfo[0]?._type === fishCategory ? classes['active'] : ''} ${classes['harvest__card-tab']}`}
                    >
                      <button
                        className={`${cardStyle === 'projected-card' ? 'heading--projected-tab' : 'heading--tab'}`}
                        onClick={() => findTabInfo(fishCategory.toString())}
                      >
                        {/* TODO - Implement icon */}
                        <Image
                          src={getIcon(fishCategory)}
                          width={32}
                          height={32}
                          alt={fish.species.title}
                        />
                        <span>
                          {fishCategory === 'fishermen'
                            ? 'Sourcing'
                            : fishCategory}
                        </span>
                      </button>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          </div>
        )}

        {tabInfo[0]?._type !== 'fishermen' && (
          <div className={classes['harvest__card-content']}>
            {tabInfo.header && (
              <h4
                className={`${cardStyle === 'projected-card' ? 'heading--projected-title' : ''}`}
              >
                {tabInfo.header}
              </h4>
            )}
            {tabInfo.subheader && <h5>{tabInfo.subheader}</h5>}
            {tabInfo.content && (
              <div className={classes['content-block']}>
                <PortableText value={tabInfo.content} />
              </div>
            )}
          </div>
        )}

        {tabInfo[0]?._type === 'fishermen' && (
          <Swiper
            slidesPerView={1}
            onSwiper={setThumbsSwiper}
            watchSlidesProgress={true}
            allowTouchMove={false}
            modules={[Navigation, Thumbs]}
            threshold={15}
            className={classes['harvest__card-swiper']}
          >
            {fish.fishermen.map((fishermen) => {
              return (
                <SwiperSlide key={`${fishermen.title}`}>
                  <div className={classes['harvest__card-content']}>
                    {fishermen.header && (
                      <h4
                        className={`${cardStyle === 'projected-card' ? 'heading--projected-title' : ''}`}
                      >
                        {fishermen.header}
                      </h4>
                    )}
                    {fishermen.subheader && <h5>{fishermen.subheader}</h5>}
                    {fishermen.content && (
                      <div className={classes['content-block']}>
                        <PortableText value={fishermen.content} />
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export default HarvestCard
