import { Rating, RoundedStar } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";

const customStyle = {
  itemShapes: RoundedStar,
  activeFillColor: '#FFBB44',
  inactiveFillColor: 'transparent',
  inactiveStrokeColor: '#FFBB44',
  activeStrokeColor: '#FFBB44',
  itemStrokeWidth: 2
}

const ReviewStars = ({ rating, maxWidth = 70 }) => {
  return <Rating style={{ maxWidth: maxWidth }} value={rating} readOnly itemStyles={customStyle}/>;
};

export default ReviewStars;
