import ResponsiveComponent from '@/components/ResponsiveComponent'
import FAQItem from './FAQItem'
import classes from './FAQs.module.scss'
import ResponsiveImage from '@/components/ResponsiveImage'

const illustrationImgLoader = ({ src }) => {
  return `${src}?w=750`
}

const FAQs = ({ fields, parentClasses }) => {
  if (!fields) return undefined

  return (
    <div className={`${classes['faq']} ${classes[parentClasses]} `}>
      {!!fields.desktopIllustration && !!fields.mobileIllustration && (
        <>
          <div className={`${classes['faq__image']}`}>
            <ResponsiveComponent
              desktopBreakpoint={'lg'}
              desktop={
                fields.desktopIllustration?.asset?.url && (
                  <ResponsiveImage
                    loader={illustrationImgLoader}
                    src={fields.desktopIllustration.asset.url}
                    alt={fields.desktopIllustration.asset.alt || 'illustration'}
                  />
                )
              }
              mobile={
                fields.mobileIllustration?.asset?.url && (
                  <ResponsiveImage
                    loader={illustrationImgLoader}
                    src={fields.mobileIllustration.asset.url}
                    alt={fields.mobileIllustration.asset.alt || 'illustration'}
                  />
                )
              }
            />
          </div>
        </>
      )}
      <div className={`${classes['faq__container']} container`}>
        <div className={classes['faq__header']}>
          <h2 className="h1">{fields.header}</h2>
        </div>
        <div className={classes['faq__content']}>
          <ul className={classes['faq__items']}>
            {fields.faqItems.map((item) => {
              return <FAQItem key={item._id} item={item} />
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FAQs
