import classes from "./FeaturedReview.module.scss";
import LeftQuote from "@/svgs/left-quote.svg";
import RightQuote from "@/svgs/right-quote.svg";
import ReviewStars from "@/components/Review/ReviewStars/ReviewStars";
import productReviewsData from '@/utils/reviews.preval';
import { useMemo } from "react";
import sanitizeHtml from 'sanitize-html';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const FeaturedReview = ({ reviewId }) => {
  const review = useMemo(() => productReviewsData.find(({ id }) => id === reviewId), [reviewId])

  if (!review) {
    return null
  }

  return (
    <section className={classes['featured-review']}>
      <div className={classes['featured-review__card']}>
        <LeftQuote className={classes['featured-review__card__left-quote']} />
        <div className={classes['featured-review__card__content']}>
          <p><strong>{sanitizeHtml(review.title)}</strong>
            <ReviewStars rating={5} maxWidth={100} />
          </p>
          <p>
            {sanitizeHtml(review.body)} <br /> <br /> -{' '}
            {review.author.split(' ')[0]}{' '}
            {review.author.split(' ')[1].charAt(0)}.
          </p>
          <div className={classes['featured-review__card__verified-buyer']}>
              <VerifiedUserIcon className={classes['featured-review__card__verified-buyer__icon']}/>
              <p>Verified Buyer</p>
            </div>
        </div>
        <RightQuote className={classes['featured-review__card__right-quote']} />
      </div>
    </section>
  )
};

export default FeaturedReview;
