import classes from './ReviewsCarousel.module.scss'
import { useCallback } from 'react'
import ReviewStars from '@/components/Review/ReviewStars/ReviewStars'
import useProductsReviews from '@/hooks/useProductsReviews'
import FeaturedReview from '../FeaturedReview/FeaturedReview'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import sanitizeHtml from 'sanitize-html';

const ReviewsCarousel = ({ fields }) => {
  const reviews = useProductsReviews(
    fields?.products?.map((product) => product?.handle?.current),
    40,
  )

  const renderCard = useCallback(
    ({ id, rating, author, title, body }) => {
      return (
        <div className={classes['reviews-carousel__item']} key={id}>
          <div className={classes['reviews-carousel__item__review']}>
            <div className={classes['reviews-carousel__item__header']}>
              <p>{sanitizeHtml(title)}</p>
              <ReviewStars rating={rating} />
            </div>
            <p>{sanitizeHtml(body)}</p>
            <p>
              {author.split(' ')[0]} {author.split(' ')[1].charAt(0)}.
            </p>
            <div className={classes['reviews-carousel__item__verified-buyer']}>
              <VerifiedUserIcon className={classes['reviews-carousel__item__verified-buyer__icon']}/>
              <p>Verified Buyer</p>
            </div>
          </div>
        </div>
      )
    },
    [],
  )

  if (fields?.featuredReview && fields?.reviewType === 'single') {
    return <FeaturedReview reviewId={Number(fields?.featuredReview)} />
  }

  if (!reviews?.length) {
    return null
  }

  return (
    <section className={classes['reviews-carousel']}>
      {reviews?.map(renderCard)}
    </section>
  )
}

export default ReviewsCarousel
