import { useMemo } from 'react'
import useProductsReviews from './useProductsReviews'
import productReviewsData from '@/utils/reviews.preval'

// TODO: why do we have so many different ways of fetching review info? We should consolidate these into a single function
const calculateAverage = (numbers) =>
  numbers.length
    ? numbers.reduce((sum, num) => sum + num, 0) / numbers.length
    : 0

export const getInfoReviewFn = (productHandles) => {
  const validHandles = productHandles.filter((handle) => !!handle)

  const reviews = productReviewsData?.filter((review) =>
    validHandles.includes(review.productHandle),
  )
  const average = calculateAverage(reviews.map((review) => review.rating))

  return {
    average: Number(average.toFixed(2)),
    totalReviews: reviews.length,
  }
}

const useProductReviewsDetails = (productHandle) => {
  const reviews = useProductsReviews([productHandle])

  const filteredInfo = useMemo(() => {
    if (!reviews.length) {
      return null
    }

    const average = calculateAverage(reviews.map((review) => review.rating))
    return {
      average: Number(average.toFixed(2)),
      totalReviews: reviews.length,
    }
  }, [reviews])

  return filteredInfo
}

export default useProductReviewsDetails
